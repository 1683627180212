import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaintenanceGuard } from '../../services/maintenance.guard';
import { ReporterTypeComponent } from './reporter-type/reporter-type.component';
import { IncidentDateComponent } from '../../incident-details/incident-date/incident-date.component';
import { PolicySearchComponent } from './policy-search/policy-search.component';
import { VehicleMakeSelectionComponent } from './vehicle-make-selection/vehicle-make-selection.component';
import { InvolvementComponent } from '../../incident-details/involvement/involvement.component';
import { AmountVehiclesComponent } from '../../incident-details/amount-vehicles/amount-vehicles.component';
import { ClaimantIncidentTypeComponent } from './claimant-incident-type/claimant-incident-type.component';
import { ClaimantContactComponent } from './claimant-contact/claimant-contact.component';
import { ClientInvolvementComponent } from './client-involvement/client-involvement.component';
import { ClaimantVehicleComponent } from './claimant-vehicle/claimant-vehicle.component';
import { ClaimInformationComponent } from './claim-information/claim-information.component';
import { DamageComponent } from '../../damage-details/damage/damage.component';
import { ClaimantDOBComponent } from './claimant-dob/claimant-dob.component';
import { AdditionalCommentsComponent } from '../../review/additional-comments/additional-comments.component';
import { VehicleSelectionComponent } from './vehicle-selection/vehicle-selection.component';
import { ClaimantVehicleLocationComponent } from './claimant-vehicle-location/claimant-vehicle-location.component';
import { VehicleStorageFeeComponent } from '../../damage-details/vehicle-storage-fee/vehicle-storage-fee.component';
import { EnterLocationComponent } from '../../incident-details/incident-location/enter-location/enter-location.component';
import { ClaimantNiDriverComponent } from './claimant-ni-driver/claimant-ni-driver.component';
import { PrimaryPolicySearchComponent } from './primary-policy-search/primary-policy-search.component';
import { SecondaryPolicySearchComponent } from './secondary-policy-search/secondary-policy-search.component';
import { DisputedLiabilityComponent } from '../../incident-details/disputed-liability/disputed-liability.component';
import { InsuredDamageComponent } from './insured-damage/insured-damage.component';

const routes: Routes = [
  { path: 'reporter-type', component: ReporterTypeComponent, canActivate: [MaintenanceGuard] },
  { path: 'incident-date', component: IncidentDateComponent, canActivate: [MaintenanceGuard] },
  { path: 'policy-search', component: PolicySearchComponent, canActivate: [MaintenanceGuard] },
  { path: 'search-policy-criteria', component: PrimaryPolicySearchComponent, canActivate: [MaintenanceGuard] },
  { path: 'match-policy-criteria', component: SecondaryPolicySearchComponent, canActivate: [MaintenanceGuard] },
  { path: 'vehicle-selection', component: VehicleSelectionComponent, canActivate: [MaintenanceGuard] },
  { path: 'vehicle-make', component: VehicleMakeSelectionComponent, canActivate: [MaintenanceGuard] },
  { path: 'involvement', component: InvolvementComponent, canActivate: [MaintenanceGuard] },
  { path: 'claimant-dob', component: ClaimantDOBComponent, canActivate: [MaintenanceGuard] },
  { path: 'amount-vehicles', component: AmountVehiclesComponent, canActivate: [MaintenanceGuard] },
  { path: 'named-insured-driver', component: ClaimantNiDriverComponent, canActivate: [MaintenanceGuard] },
  { path: 'oic-contact', component: ClaimantContactComponent, canActivate: [MaintenanceGuard] },
  { path: 'client-contact', component: ClaimantContactComponent, canActivate: [MaintenanceGuard] },
  { path: 'claimant-contact', component: ClaimantContactComponent, canActivate: [MaintenanceGuard] }, 
  { path: 'claimant-incident-type', component: ClaimantIncidentTypeComponent, canActivate: [MaintenanceGuard] },
  { path: 'client-involvement', component: ClientInvolvementComponent, canActivate: [MaintenanceGuard] },
  { path: 'client-vehicle', component: ClaimantVehicleComponent, canActivate: [MaintenanceGuard] },
  { path: 'claimant-vehicle', component: ClaimantVehicleComponent, canActivate: [MaintenanceGuard] },
  { path: 'claim-information', component: ClaimInformationComponent, canActivate: [MaintenanceGuard] },
  { path: 'damage', component: DamageComponent, canActivate: [MaintenanceGuard] },
  { path: 'vehicle-location', component: ClaimantVehicleLocationComponent, canActivate: [MaintenanceGuard] },
  { path: 'enter-location', component: EnterLocationComponent, canActivate: [MaintenanceGuard] },
  { path: 'vehicle-storage', component: VehicleStorageFeeComponent, canActivate: [MaintenanceGuard] },
  { path: 'additional-comments', component: AdditionalCommentsComponent, canActivate: [MaintenanceGuard] },
  { path: 'disputed-liability', component: DisputedLiabilityComponent, canActivate: [MaintenanceGuard]},
  { path: 'insured-damage', component: InsuredDamageComponent, canActivate: [MaintenanceGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimantIncidentDetailsRoutingModule { }
