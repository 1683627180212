import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserIdleService } from './user-idle/user-idle.service';
import { fromEvent, merge, Observable } from 'rxjs';
import { ErrorHandlingService } from '../../../lossreporting-library/src/lib/services/helpers/error-handling.service';
import { SfAnalyticsService } from '../../../lossreporting-library/src/lib/services/sf-analytics.service';
import { HostnameBuilderService } from '../../../lossreporting-library/src/lib/services/helpers/hostname-builder.service';
import { CookieHandlerService } from 'projects/lossreporting-library/src/lib/services/helpers/cookie-handler.service';
import { parseJwt, exchangeOauth2Tokens } from 'customer-oauth2-token-management-utility';
import { AuthenticationService } from 'projects/lossreporting-library/src/lib/services/authentication.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'lossreporting-app';

  @ViewChild('modalIdleTimeout', { static: false }) modalIdleTimeout: ElementRef;

  AUTH_TIMEOUT = 2;
  counter$: Observable<number>;
  countdown = 120;
  modalCountdown: number;
  counter = false;
  count: number = 0;
  counterText: string;

  activityDetected = false;
  warningTriggered = false;
  modalText = 'Do you want to continue?';
  acceptButton = 'Yes';
  denyButton = 'No';
  clicked = false;
  tokenExchange = exchangeOauth2Tokens; // set to variable for testing purposes
  authParseJwt = parseJwt;

  constructor(
    private router: Router,
    private userIdle: UserIdleService,
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    private errorHandlingService: ErrorHandlingService,
    private hostnameBuilder: HostnameBuilderService,
    private cookieService: CookieHandlerService,
    private authenticationService: AuthenticationService,
    private titleService: Title,
  ) { 
    // Subscribe to router events to update the title browser tab
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateTitle();
    });
  }

  private updateTitle() {
    const url = this.window.location.pathname || '';
    const segments = url.split('/');
    const endPath = segments.length > 0 ? segments[segments.length - 1] : ''; 
    const capitalizedEndPath = endPath.split('-')
      .map(segment => segment.charAt(0).toUpperCase() + segment.slice(1))
      .join(' ');

    // Set the document title
    this.titleService.setTitle('File A Loss - ' + capitalizedEndPath);
  }

  ngAfterViewInit() {
    this.window['oneX'].addElement(document.querySelector('#olr-footer'));
  }

  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#olr-footer'));
    this.authenticationService.stopTimer();
  }

  // Force all pages to load at the top of the users viewport
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
     /// Grabs the last part of the segmented url for browser tab and handles capitilization
    const url = this.window.location.pathname || '';
    const segments = url.split('/');
    const endPath = segments.length > 0 ? segments[segments.length - 1] : '';
    const capitalizedEndPath = endPath.split('-').map(endPath => endPath.charAt(0).toUpperCase() + endPath.slice(1)).join(' '); 
    this.titleService.setTitle('File A Loss: ' + capitalizedEndPath);
    //const url = this.window.location.pathname || '';
    if (url.includes('claimant')) {
      this.userIdle.setConfigValues({ idle: 1620, timeout: 120, ping: 60 });
      this.modalText = 'Would you like to continue?';
      this.acceptButton = 'Yes, continue';
      this.denyButton = 'Exit';
    }
    this.userIdle.setCustomActivityEvents(
      merge(
        fromEvent(document, 'mousedown'),
        fromEvent(document, 'keydown'),
        fromEvent(document, 'touchstart'),
        fromEvent(document, 'touchend'))
    );

    // Start watching for user inactivity.
    this.userIdle.startWatching();

    this.userIdle.onIdleStatusChanged().subscribe((isIdle) => {
      if (!isIdle || this.shouldSkipExpire()) {
        this.activityDetected = true;
      }
    });

    this.userIdle.ping$.subscribe(() => {
      if(this.shouldSkipExpire()) { return; }
      if (this.activityDetected && !this.warningTriggered) {
        this.refreshKeepAlive();
      }
      this.activityDetected = false;
    });

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      if(this.shouldSkipExpire()) { return; }
      if (this.count === this.countdown - 1) {
        this.warningTriggered = true;
        // @ts-ignore
        this.window.oneX.Modal.showModal(this, 'modal-idle-timeout');
        this.sfAnalyticsService.sendData('session-expiring');
      }
      this.count = this.countdown - count;
      this.setCounterText(this.count);
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      // @ts-ignore
      this.window.oneX.Modal.hideModal();
      this.errorHandlingService.setCurrentPage(window.location.href);
      const path = this.window.location.pathname || '';
      if (path.includes('/confirmation/')) {
        this.router.navigateByUrl('/session/session-expired-confirmation');
      } else {
        this.router.navigateByUrl('/session/session-expired');
      }

    });
  }

  private setCounterText(timerCount: number): void {
    const minutes = Math.floor(timerCount / 60);
    const seconds = timerCount % 60;

    if (minutes === 0) {
      this.counterText = `${seconds} seconds`;
    } else if (minutes === 1) {
      this.counterText = `${minutes} minute ${seconds} seconds`;
    } else {
      this.counterText = `${minutes} minutes ${seconds} seconds`;
    }
  }

  keepAlive() {
    const url = this.window.location.pathname || '';
    if (!url.includes('claimant')) {
      this.clicked = true;
      this.authenticationService.validateRefreshAuthentication().then(res => {
        this.userIdle.resetTimer();
        this.warningTriggered = false;
        // @ts-ignore
        this.window.oneX.Modal.hideModal();
        this.clicked = false;
      }).catch(err => {
        this.clicked = false;
        this.window.location.href = 'https://proofing' + this.hostnameBuilder.getHostname() + '/login-ui/logout';
      });
    }
  }

  private shouldSkipExpire(): boolean {
    const path = this.window.location.pathname || '';
    if(!(!!this.cookieService.get('sf-icp-sso') || !!this.cookieService.get('sf-cauth1')) && path.includes('start-claim')) {
      this.userIdle.resetTimer();
      return true;
    }
    return false;
  }

  logout(id: string): void {
    this.authenticationService.stopTimer();
    // @ts-ignore
    this.window.oneX.Modal.hideModal();
    this.window.location.href = this.hostnameBuilder.getClaimURL();
  }

  private refreshKeepAlive() {
    const id = 'keepAliveImage';
    const prev = document.getElementById(id);
    if (prev) {
      prev.remove();
    }
    const imgNode = document.createElement('img');
    imgNode.id = id;
    imgNode.className = '-oneX-hidden';
    document.body.appendChild(imgNode);
  }

}

