import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-insured-damage',
  templateUrl: './insured-damage.component.html',
  styleUrls: ['./insured-damage.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class InsuredDamageComponent extends BaseComponent implements OnInit {
  private reporterType = 'individual';

  get swapText(): string {
    return this.reporterType === 'otherInsuranceCompany' ? 'your client\'s' : 'your';
  }

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
      this.determineReporter();
     }

  ngOnInit() {
    this.analytics.sendData('damage-parent-question');
    this.highlightButtonStandardAnswer();
  }

  private determineReporter() {
    const url = this.router.url;
    if (url && url.includes('claimant')) {
      const step = this.lossReportService.getStepByName('claimantType');
      this.reporterType = step ? step.question.answers[0] : 'individual';
    }
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.vehicleDamageCondition = answer;
    this.logService.log(CliffLogCodes.VEHICLE_DAMAGED_CONDITION, logDetails);
    this.updateAnswer(answer);
  }

}
