// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hostname: 'hub.claims.test.statefarm.com',
  // api urls
  api_auth: 'https://api.claims.test.c1.statefarm/claimutility/api/authentication',
  api_eligibility: 'https://claimsvc-env1.test.statefarm.com/claimservices-api-services/v5/',
  api_liteocs: 'https://claimsvc-env1.test.statefarm.com/liteocs/',
  api_preferences: 'https://api.claims.test.c1.statefarm/claimexperience-api-preferences',
  // eslint-disable-next-line max-len
  api_sfuid: 'https://sfuid-env1.cfapps-71a.opr.test.statefarm.org/SFUIDCookieApplication-services-web/services/SFUID/getSFUID', // This returns the sfuid used for medallia
  api_status: 'https://claimprogressretrieval-env1.cfapps-71b.opr.test.statefarm.org/claimprogressretrieval-api/v4',
  // app urls
  app_compliance: 'https://claimsvc-env1.test.statefarm.com/claimservices-api-compliance/v1',
  app_rental: 'https://rental.claims.test.statefarm.com',
  app_repair: 'https://repair.claims.test.c1.statefarm.com/shopsearch',
  // app envs
  app_fileupload: 'env1',
  app_photos_docs: 'env1',
};
