import { Injectable,  } from '@angular/core';
import { CookieHandlerService } from './helpers/cookie-handler.service';
import { CIHEvent } from '../models/cih-event.model';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { LogService } from '../services/log.service';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { LogDetails } from '../models/log-details.model';
import { Router } from '@angular/router';
import { Subject, takeUntil, pipe } from 'rxjs';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsEventsService {
  currentPath = sessionStorage.getItem('flowPath');
  isValid: boolean;
  payloadQueue = []
  cihEventSent = new Subject<any>();

  constructor(
    private http: HttpClient,
    private cookieHandlerService: CookieHandlerService,
    private hostnameBuilderService: HostnameBuilderService,
    private logService: LogService,
    private router: Router,
    private featureFlagService: FeatureFlagService

    ) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.cookieHandlerService.getOauthToken(),
        })
      }
    }

  private httpOptions;

  private sendEvent(eventData: CIHEvent){

    const url =  this.hostnameBuilderService.getEventUrl();
    this.http
        .post<any>(url, eventData, this.httpOptions).subscribe({})
    this.logService.log(CliffLogCodes.CIH_EVENT_SENT)

  }

  private getEnterpriseInteractionId(): string {
    const result = this.cookieHandlerService.get('enterprise_interaction_identifier=');
    return result;
  }

  private generateTimeStamp(date: Date): string {
    return date.toISOString();
  }

  private getTimeZone(date: Date): string {
    const timeFormat: Intl.DateTimeFormatOptions = { month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false, timeZoneName: 'short', timeZone: 'UTC' }
    const formatter = new Intl.DateTimeFormat('en-US', timeFormat);
    const parts = formatter.formatToParts(date);

    const timezonePart = parts.find(part => part.type === 'timeZoneName');
    const timezoneAbbreviation = timezonePart ? timezonePart.value : '';
    return timezoneAbbreviation;
  }

  private getSFUId(): string {
    return this.cookieHandlerService.get('sfuid=');
  }

  private getTrigger(path: string, eventText: string): string {
    let result;
    const triggerTypes = {
      'filealoss-auto': 'AUTO',
      'filealoss-fire': 'FIRE',
      'filealoss-claimant': 'CLAIMANT',
      'filealoss-unauthenticated': 'CLAIMANT-NOAUTH',
      'filealoss-OIC': 'OIC',
      'claims': 'LANDING'
    }
    eventText === 'Report Claim' ? result = 'LANDING' : result = triggerTypes[path];
    return result;
  }
  /**Leaving in case IIH would like individual participant ids */
  // private getParticipantID(): string {
  //   const participantsArray = sessionStorage.getItem('participants');
  //   //check to see if this is what the session storage variable is called in claimant and oic
  //   const additionParticipant = sessionStorage.getItem('additionalParticipants');
  //   if(participantsArray){
  //     const lastId = JSON.parse(participantsArray)[JSON.parse(participantsArray).length-1].id;

  //     return lastId;
  //   }
  //   return undefined;
  // }

  private getIntent(path: string, eventText: string): string {
    let intent;
    const intentTypes = {
      'filealoss-fire': 'Report Fire Claim',
      'filealoss-auto': 'Report Insured Auto Loss',
      'filealoss-claimant': 'File Claim - Claimant',
      'filealoss-unauthenticated': 'File Claim - Claimant NOAUTH',
      'filealoss-OIC': 'File Claim - OIC',
      'claims': 'Report a Claim',
    }
    eventText === 'Report Claim' ? intent = 'Report a Claim': intent = intentTypes[path]
    return intent
  }

  private getLineOfBusiness(path: string, eventText: string): string {
    let result = "";
    const mimeTypes = {
      'filealoss-auto': 'Auto',
      'filealoss-fire': 'Fire',
      'filealoss-claimant': 'Auto',
      'filealoss-unauthenticated': 'Auto',
      'filealoss-OIC': 'Auto',
    }
    eventText === 'Report Claim' ? result = "" : result = mimeTypes[path];
    return result;
  }

  private evalEventText(fp:string, text: string): string{

    const fpTypes ={
      'filealoss-auto': 'Auto Insured',
      'filealoss-fire': 'Fire',
      'filealoss-claimant': 'Claimant',
      'filealoss-unauthenticated': 'No Auth',
      'filealoss-OIC': 'OIC',
    }
    if(text === 'Claim Started'){
      text = text += ` - ${fpTypes[fp]}`
    }
      return text;
  }

  private getExternalClaimId() {
    const id = sessionStorage.getItem('claimId');
    return id
  }

  private getClaimsRoleName(path: string, eventText: string): string{
    let result;
    const claimTypes = {
      'filealoss-fire': 'NamedInsured',
      'filealoss-auto': 'NamedInsured',
      'filealoss-claimant': 'Claimant',
      'filealoss-unauthenticated': 'Claimant',
      'filealoss-OIC': 'Claimant - OIC',
      'claims': 'OLR Landing'
    }
    eventText === 'Report Claim' ? result = claimTypes['claims'] : result = claimTypes[path]
    return result;
  }
  private getNumber(text: string): number {
    const eventTypes = {
      'Report Claim' : 22,
      'Claim Started': 23,
      'Capture Participant Details': 24,
      'Contact Preference': 25,
      'Capture Loss Details': 26,
      'Review Claim': 27,
      'Claim Confirmed': 28,
      'Policy Not Found': 29,
      'Duplicate Claim': 30,
      'Claim Cancelled':31,
      'Technical Error': 32,
      'Session Expired':33,
    }
    return eventTypes[text]
  }

  //ideally should not need a fallback on url
  private getFilePath(url: string): string | void{
    if(url.includes('homeandproperty')){
      sessionStorage.setItem('flowPath', 'filealoss-fire');
      return 'filealoss-fire';
    }

    else if(url.includes('opt-in/preferences')){
      return;
    }

    else{
      sessionStorage.setItem('flowPath', 'filealoss-auto');
      return 'filealoss-auto';
    }
  }

  private checkEvent(event: CIHEvent): boolean {
    this.isValid = true
    for(const [k,v] of Object.entries(event)){

      if(k === 'externalClaimId' && !v){
        delete event['externalClaimId']
        continue;
      }

      if(k !== 'enterprise_interaction_identifier' && k !== 'custom' && !v){
        this.isValid = false;
        break;
      }

      if(k === 'trigger' && event.customs.enterpriseInteractionEventText !== 'Report Claim' && v === 'LANDING'){

        this.isValid = false // logs after isValid is false
        break;
      }
      if(k === 'number' && v === 28 && this.payloadQueue.length > 0 ){
        this.payloadQueue = []
        this.isValid = false; // logs after isValid is false
        break;
      }

      if(k === 'externalClaimId' && event.number !== 28 && v){

        this.isValid = false; //externalClaimId should never be populated unless the claim is confirmed
        break;
      }
      if(event.number === 28 && !event.enterprise_interaction_identifier){

        this.isValid = false;
        break;
      }

  }
  return this.isValid;
}

  private emptyQueue(eiid: string, sfuid?: string){
    const queueCopy = this.payloadQueue;

    this.payloadQueue.forEach(element => {
      // console.log(element, 'event to be modified')
      const custObj = JSON.parse(element.custom)
      if(sfuid && !custObj.stateFarmUserIdentifier){
        custObj.stateFarmUserIdentifier = sfuid;
      }
      element.custom = JSON.stringify(custObj)
      element.enterprise_interaction_identifier = eiid
      // console.log(element, 'element was modified')
      this.sendEvent(element);
        queueCopy.shift();
    });

    this.payloadQueue = queueCopy
  }

  private handleEvent(eiid: string, event: CIHEvent){
    eiid ? this.sendEvent(event) : this.payloadQueue = [...this.payloadQueue, event]
  }

  privateCheckEventText(text: string): boolean{
    let result;
    const eventTypes = {
      'Report Claim' : 22,
      'Claim Started': 23,
      'Capture Participant Details': 24,
      'Contact Preference': 25,
      'Capture Loss Details': 26,
      'Review Claim': 27,
      'Claim Confirmed': 28,
      'Policy Not Found': 29,
      'Duplicate Claim': 30,
      'Claim Cancelled':31,
      'Technical Error': 32,
      'Session Expired':33,
    }
    eventTypes[text] ? result = true: result = false
    return result
  }

  async buildEvent(eventText: string) : Promise<CIHEvent> {
    const event = new CIHEvent();
    let details = new LogDetails();
    details.serviceErrorMessage = `INVALID DATA USED TO BUILD EVENT: ${eventText}`
    if(!this.privateCheckEventText(eventText)){
      //log an error? Revoke Okta Token/logout?
      this.logService.log(CliffLogCodes.CIH_EVENT_CANCELLED, details)
      return event;
    }
    const eventDate : Date = new Date();

    let url = this.router.url;
    const eiid = this.getEnterpriseInteractionId();
    const sfuid = this.getSFUId();
    let fpath;

      const tpath = sessionStorage.getItem('flowPath');
      if(tpath){
        fpath = tpath
      }else{
        //fallback
        fpath = this.getFilePath(url)
        if(!fpath){

          const errMessage = {
            'The flowpath was empty and the url does not indicate where we are in the flow': `eiid: ${eiid}, sfuid: ${sfuid}`
          };
          details.serviceErrorMessage = JSON.stringify(errMessage);
          this.logService.log(CliffLogCodes.CIH_EVENT_CANCELLED, details);
          return event;
        }
      }

    const fp = fpath
    //should add this to generate error function
    const errObj = {
      'eventText': eventText,
      'fp': fp,
      'sfuid': sfuid,
      'eiid': eiid
    }

    if(!this.featureFlagService.isClaimsEventingEnabled()){
      //will only be checking eiid from this point on since we have added the above fpath logic

      if(!eiid){
        const errMessage = {
          'The feature flag was turned off and still encountered an error': errObj
        };
        details.serviceErrorMessage = JSON.stringify(errMessage)
        this.logService.log(CliffLogCodes.CIH_EVENT_CANCELLED, details);
        return event;
      }
        const errMessage = {
          'The feature flag was turned off': errObj
        };
        details.serviceErrorMessage = JSON.stringify(errMessage)
        this.logService.log(CliffLogCodes.CIH_EVENT_CANCELLED, details);
        return event;

    }

    if(this.featureFlagService.isClaimsEventingEnabled()){

    //Don't want to track OIC and Claimants until the endpoint is ready.. don't have to test since this will be deleted.
    if(fp === 'filealoss-claimant' || fp === 'filealoss-OIC' || fp === 'filealoss-unauthenticated'){
      return event;
    }

    [
      event.area,
      event.number,
      event.trigger,
      event.customs.enterpriseInteractionIntentName,
      event.enterprise_interaction_identifier,
      event.customs.producerInteractionTimestamp,
      event.customs.producerInteractionTimezone,
      event.publisher,
      event.customs.enterpriseInteractionEventText,
      event.externalClaimId,
      event.customs.claimsRoleName,
      event.customs.levelOfAssuranceNumber,
      event.customs.lineOfBusinessName,
      event.customs.stateFarmUserIdentifier,
    ] =
    await Promise.all([
      'CIH',
      this.getNumber(eventText),
      this.getTrigger(fp, eventText),
      this.getIntent(fp, eventText),
      eiid,
      this.generateTimeStamp(eventDate),
      this.getTimeZone(eventDate),
      'OLR',
      this.evalEventText(fp,eventText),
      this.getExternalClaimId(),
      this.getClaimsRoleName(fp, eventText),
      '4',
      this.getLineOfBusiness(fp, eventText),
      sfuid,
    ]);

    // If not valid then return, several rules exist in checkEvent.
  this.isValid = this.checkEvent(event)

  //if not valid then log the error and return
    if(!this.isValid){

      if(this.payloadQueue.length > 0){
        const errMessage = { 'Event object did not send. The event was invalid and there were items in the queue': {
          'queue': this.payloadQueue,
          'event': event
        }};

        details.serviceErrorMessage = JSON.stringify(errMessage);
        this.logService.log(CliffLogCodes.CIH_EVENT_CANCELLED, details);
        return event;

        //if not valid and nothing in queue
      }else{
      const errMessage = { 'Event object did not send': {
        'event': event
      }};
      // console.log('INVAILD')
      details.serviceErrorMessage = JSON.stringify(errMessage);
      this.logService.log(CliffLogCodes.CIH_EVENT_CANCELLED, details);
      return event;
    }
    }

    event.custom = JSON.stringify(event.customs)

    delete event['customs'];
    //If we are in the entry point but there are events in the queue before we handleEvent clear the queue.
    //There will be items in the queue most times on this page after handleEvent due to eiid loading times
    if(fp === 'claims' && this.payloadQueue.length > 0){
      //probably don't want to make it invalid as it would most likely be an unintended consequence from a prior flow
      this.payloadQueue = [];
      //log?
    }
    //adds current event to the queue if invalid or sends to publisher
    this.handleEvent(eiid,event); //send or store

    if(eiid && this.payloadQueue.length > 0){ //if stored items
      while(this.payloadQueue.length > 0){//had to brute force it, should only be max 3 items
        this.emptyQueue(eiid, sfuid);
      }
    }

    return event;
    }
  }
}
