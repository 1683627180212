import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReceivedAutoClaimComponent } from '../confirmation/received-auto-claim/received-auto-claim.component';
import { ReceivedClaimantClaimComponent } from '../confirmation/received-claimant-claim/received-claimant-claim.component';
import { ReceivedPropertyClaimComponent } from '../confirmation/received-property-claim/received-property-claim.component';
import { PleaseWaitComponent } from './please-wait/please-wait.component';
import { SaveConfirmationComponent } from './save-confirmation/save-confirmation.component';
import { AuthGuard } from '../services/guards/auth.guard';

const routes: Routes = [
    { path: 'received-auto-claim', component: ReceivedAutoClaimComponent, canActivate: [AuthGuard] },
    { path: 'received-claimant-claim', component: ReceivedClaimantClaimComponent },
    { path: 'please-wait', component: PleaseWaitComponent, canActivate: [AuthGuard]},
    { path: 'received-property-claim', component: ReceivedPropertyClaimComponent, canActivate: [AuthGuard] },
    { path: 'save-confirmation', component: SaveConfirmationComponent, canActivate: [AuthGuard] }
];
@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class ConfirmationRoutingModule {}
