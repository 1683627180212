import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClaimantRoutingModule } from './claimant-routing.module';
import { ClaimantIncidentDetailsModule } from './claimant-incident-details/claimant-incident-details.module';
import { SharedModule } from '../shared/shared.module';
import { ClaimantLandingComponent } from './claimant-landing/claimant-landing.component';
import { ClaimantReviewModule } from './claimant-review/claimant-review.module';
import { ClaimantAdditionalDetailsModule } from './claimant-additional-details/claimant-additional-details.module';



@NgModule({
  declarations: [ClaimantLandingComponent],
  imports: [
    CommonModule,
    ClaimantRoutingModule,
    ClaimantIncidentDetailsModule,
    ClaimantReviewModule,
    ClaimantAdditionalDetailsModule,
    SharedModule
  ],
  exports: [
    SharedModule,
  ]
})
export class ClaimantModule { }
