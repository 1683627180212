//  id for each CliffLogCode MUST be unique and used in only 1 location

export class CliffLogCodes {
  public static LOGLEVEL = {INFO: 'info', ERROR: 'error'};
  public static CP_RTRV_SUC = {id: 'CP_RTRV_SUC', text: 'LRE customer retrieval call successful', status: 200, svcName: 'details/customer'};
  public static CP_RTRV_FAIL = {id: 'CP_RTRV_FAIL', text: 'LRE customer retrieval call failed'};
  public static PAGE_LOG = {id: 'PAGE_LOG', text: 'Page displayed', svcName: 'SfAnalyticsService'};
  public static MDLLIA = {id: 'MDLLIA', text: 'Medallia called successfully', svcName: 'MedalliaService'};
  public static ADDR_UPDATE = {id: 'ADDR_UPDATE', text: 'Address Update', svcName: 'CustomerContactInformationComponent'};
  public static ADDR_UPDATE_FAIL = {id: 'ADDR_UPDATE_FAIL', text: 'Address is undefined', svcName: 'CustomerContactInformationComponent'};
  public static LR_RETRIEVED = {id: 'LR_RETRIEVED', text: 'Loss report retrieved', status: 200, svcName: 'claimloss-api-lossreports'};
  public static LR_CREATED = {id: 'LR_CREATED', text: 'Loss report created', status: 201, svcName: 'claimloss-api-lossreports'};
  public static LR_RECREATED = {id: 'LR_RECREATED', text: 'Loss report recreated for new lrid', status: 201, svcName: 'claimloss-api-lossreports'};
  public static LR_UPDATED = {id: 'LR_UPDATED', text: 'Loss report updated', status: 201, svcName: 'claimloss-api-lossreports'};
  public static LR_DELETED = {id: 'LR_DELETED', text: 'Loss report deleted', status: 201, svcName: 'claimloss-api-lossreports'};
  public static LR_SUBMITTED = {id: 'LR_SUBMITTED', text: 'Loss report submitted', status: 201, svcName: 'claimloss-api-lossreports'};
  public static LR_RETRIEVE_FAIL = {id: 'LR_RETRIEVE_FAIL', text: 'Failed to retrieve loss report', svcName: 'claimloss-api-lossreports'};
  public static LR_CREATE_FAIL = {id: 'LR_CREATE_FAIL', text: 'Failed to create loss report', svcName: 'claimloss-api-lossreports'};
  public static LR_RECREATE_FAIL = {id: 'LR_RECREATE_FAIL', text: 'Failed to recreate loss report for new lrid', svcName: 'claimloss-api-lossreports'};
  public static LR_UPDATE_FAIL = {id: 'LR_UPDATE_FAIL', text: 'Failed to update loss report', svcName: 'claimloss-api-lossreports'};
  public static LR_DELETE_FAIL = {id: 'LR_DELETE_FAIL', text: 'Failed to delete loss report', svcName: 'claimloss-api-lossreports'};
  public static LR_SUBMIT_FAIL = {id: 'LR_SUBMIT_FAIL', text: 'Failed to submit loss report', svcName: 'claimloss-api-lossreports'};
  public static LR_COVERAGES_RETRIEVED = {id: 'LR_COVERAGES_RETRIEVED', text: 'Loss report coverages retrieved', status: 200, svcName: 'claimloss-api-lossreports'};
  public static LR_COVERAGES_RETRIEVE_FAIL = {id: 'LR_COVERAGES_RETRIEVE_FAIL', text: 'Loss report coverages retrieve failed', svcName: 'claimloss-api-lossreports'};
  public static POL_RTRV_FAIL = {id: 'POL_RTRV_FAIL', text: 'claimpolicy-api-policies call failed', svcName: 'claimloss-api-lossreports'};
  public static POL_RTRV_SUC = {id: 'POL_RTRV_SUC', text: 'Get policies successful', status: 200, svcName: 'claimpolicy-api-policies'};
  public static CLM_DTL_SUC = {id: 'CLM_DTL_SUC', text: 'Claim details succeeded', status: 200, svcName: 'claimfile-api-claims'};
  public static CLM_DTL_FAIL = {id: 'CLM_DTL_FAIL', text: 'Claim details failed', svcName: 'claimfile-api-claims'};
  public static VEH_TYPE = {id: 'VEH_TYPE', text: 'Selected Vehicle Type', svcName: 'PolicyVehicleInfoService'};
  public static GOOGLE_API_RESPONSE = {id: 'GOOGLE_API_RESPONSE', text: 'Raw response from google maps'};
  public static INJ_PRT_ADD = {id: 'INJ_PRT_ADD', text: 'added insured participants'};
  public static INJ_PRT_RMV = {id: 'INJ_PRT_RMV', text: 'removed selected insured participant'};
  public static INJ_DTL_RMV = {id: 'INJ_DTL_RMV', text: 'removed selected insured participant'};
  public static INJ_DTL_EDT = {id: 'INJ_DTL_EDT', text: 'edit selected insured participant'};
  public static INJ_NO_ONE = {id: 'INJ_NO_ONE', text: 'selected no one else was in the vehicle'};
  public static SLCT_ADD_PRT = {id: 'SLCT_ADD_PRT', text: 'selected additional participants needed'};
  public static OPTIN_EMAIL_SUCCESS = {id: 'OPTIN_EMAIL_SUCCESS', text: 'opt-in preference for email address successful'};
  public static OPTIN_EMAIL_FAILURE = {id: 'OPTIN_EMAIL_FAILURE', text: 'opt-in preference for email address failed'};
  public static OPTOUT_EMAIL_SUCCESS = {id: 'OPTOUT_EMAIL_SUCCESS', text: 'opt-out preference for email successful'};
  public static OPTOUT_EMAIL_FAILURE = {id: 'OPTOUT_EMAIL_FAILURE', text: 'opt-out preference for email failed'};
  public static OPTIN_TEXT_SUCCESS = {id: 'OPTIN_TEXT_SUCCESS', text: 'opt-in preference for phone number successful'};
  public static OPTIN_TEXT_FAILURE = {id: 'OPTIN_TEXT_FAILURE', text: 'opt-in preference for phone number failed'};
  public static OPTOUT_TEXT_SUCCESS = {id: 'OPTOUT_TEXT_SUCCESS', text: 'opt-out preference for text successful'};
  public static OPTOUT_TEXT_FAILURE = {id: 'OPTOUT_TEXT_FAILURE', text: 'opt-out preference for text failed'};
  public static PREF_RTV_SUC = {id: 'PREF_RTV_SUC', text: 'preferences-api get preferences successful'};
  public static PREF_RTV_FAIL = {id: 'PREF_RTV_FAIL', text: 'preferences-api get preferences failed'};
  public static PREF_RTV_SUC_CONFIRM = {id: 'PREF_RTV_SUC_CONFIRM', text: 'preferences-api get successful on confirmation page'};
  public static PREF_RTV_FAIL_CONFIRM = {id: 'PREF_RTV_FAIL_CONFIRM', text: 'preferences-api get failed on confirmation page'};
  public static PREF_EMAIL_UPD_SUC = {id: 'PREF_EMAIL_UPD_SUC', text: 'preferences-api email address update successful'};
  public static PREF_EMAIL_UPD_FAIL = {id: 'PREF_EMAIL_UPD_FAIL', text: 'preferences-api email address update failed'};
  public static PREF_TEXT_UPD_SUC = {id: 'PREF_TEXT_UPD_SUC', text: 'preferences-api phone number update successful'};
  public static PREF_TEXT_UPD_FAIL = {id: 'PREF_TEXT_UPD_FAIL', text: 'preferences-api phone number update failed'};
  public static ADDL_PRT_ADD = {id: 'ADDL_PRT_ADD', text: 'added additional insured vehicle participants'};
  public static ADDL_PRT_RMV = {id: 'ADDL_PRT_RMV', text: 'removed additional insured vehicle participant'};
  public static INJ_NO_RMV_NO = {id: 'INJ_NO_RMV_NO', text: 'selected no on the modal to remove a participant'};
  public static CLM_ADD_PRT = {id: 'CLM_ADD_PRT', text: 'added claimant vehicle participant'};
  public static CLM_PRT_RMV = {id: 'CLM_PRT_RMV', text: 'removed claimant vehicle participant'};
  public static CLM_PRT_EDT = {id: 'CLM_PRT_EDT', text: 'editing claimant vehicle participant'};
  public static FATALITY = {id: 'FATALITY', text: 'there was a fatality listed on the loss report'};
  public static INJURY = {id: 'INJURY', text: 'there were injuries listed on the loss report'};
  public static CLMT_PLCY_SRCH = {id: 'CLMT_PLCY_SRCH', text: ''};
  public static MAKE_ATTEMPT = {id: 'MAKE_ATTEMPT', text: 'attempted to match policy with vehicle make'};
  public static CLAIMANT_INJURY_STATUS = {id: 'CLAIMANT_INJURY_STATUS', text: 'Claimant injury status'};
  public static INJ_NO = {id: 'INJ_NO', text: 'selected no on injury status'};
  public static INJ_YES = {id: 'INJ_YES', text: 'selected yes on injury status'};
  public static INJ_UNKNOWN = {id: 'INJ_UNKNOWN', text: 'selected no on injury status'};
  public static FATALITY_YES = {id: 'FATALITY_YES', text: 'selected yes on fatality status'};
  public static CLMNT_SBMT_SUC = {id: 'CLMNT_SBMT_SUC', text: 'claimant submission successful'};
  public static FIRE_SBMT_SUC = {id: 'FIRE_SBMT_SUC', text: 'fire submission successful'};
  public static UNSUPPORTED_ASSOCIATE = {id: 'UNSUPPORTED_ASSOCIATE', text: 'attempted claimant loss report by associate'};
  public static LBLTY_DTRM = {id: 'LBLTY_DTRM', text: 'has liability been determined'};
  public static LBLTY_PRCNT = {id: 'LBLTY_PRCNT', text: 'liability percentage more than 0 recorded'};
  public static LBLTY_PYMNT = {id: 'LBLTY_PYMNT', text: 'has a payment been made'};
  public static SELECTED_FIRE_POLICY = {id: 'SELECTED_FIRE_POLICY', text: ''};
  public static SELECTED_PLM_POLICY = {id: 'SELECTED_PLM_POLICY', text: 'PLM Policy Selected'};
  public static SELECTED_NON_PLM_POLICY = {id: 'SELECTED_NON_PLM_POLICY', text: 'Non-PLM Auto Policy Selected'};
  public static OLR_HAGERTY_SUCCESS = {id: 'OLR_HAGERTY_SUCCESS', text: 'Successful call to Hagerty'};
  public static OLR_HAGERTY_FAILED = {id: 'OLR_HAGERTY_FAILED', text: 'Unsuccessful call to Hagerty'};
  public static OPTIN_AGMT_ACCEPT = {id: 'OPTIN_AGMT_ACCEPT', text: 'text-notification-agreed-checked'};
  public static OPTIN_AGMT_REJECTED = {id: 'OPTIN_AGMT_REJECTED', text: 'text-notification-agreed-unchecked'};
  public static UNAUTH = {id: 'UNAUTH', text: 'Unauthorized access'};
  public static MAINT_ERR = {id: 'MAINT_ERR', text: 'Maintenance error'};

  public static SELECTED_VEH_MAKE = {id: 'SELECTED_VEH_MAKE', text: 'Selected Vehicle Answer'};
  public static FINAL_LOCATION_ADDRESS = {id: 'FINAL_LOCATION_ADDRESS', text: 'Final Location of Incident'};
  public static SELECTED_INCIDENT_TYPE = {id: 'SELECTED_INCIDENT_TYPE', text: 'Selected Incident Type'};
  public static CLAIMANT_REPORTER_TYPE = {id: 'CLAIMANT_REPORTER_TYPE', text: 'Claimant Reporter Type'};
  public static VEHICLE_CONDITION = {id: 'VEHICLE_CONDITION', text: 'Condition of damaged vehicle'};
  public static VEHICLE_DAMAGED_CONDITION = {id: 'VEHICLE_DAMAGED_CONDITION', text: 'Was there any damage to the Insured vehicle'};
  public static DAMAGED_COMPONENTS = {id: 'DAMAGED_COMPONENTS', text: 'List of damaged components on the vehicle'};
  public static VEHICLE_DAMAGE_LOC = {id: 'VEHICLE_DAMAGE_LOC', text: 'List of damaged locations on the vehicle'};
  public static VEHICLE_ROLL_OVER = {id: 'VEHICLE_ROLL_OVER', text: 'Did the vehicle roll over'};
  public static FIRE_CAUSE = {id: 'FIRE_CAUSE', text: 'What caused the fire'};
  public static OTHER_CAUSE = {id: 'OTHER_CAUSE', text: 'What caused the fire'};
  public static SEWER_CAUSE = {id: 'SEWER_CAUSE', text: 'What caused the sewer or septic loss'};
  public static SUMP_PUMP_CAUSE = {id: 'SUMP_PUMP_CAUSE', text: 'What caused the sump pump loss'};
  public static WATER_DAMAGE_CAUSE = {id: 'WATER_DAMAGE_CAUSE', text: 'What caused the water damage'};
  public static WEATHER_DAMAGE_TYPE = {id: 'WEATHER_DAMAGE_TYPE', text: 'What type is the weather damage'};
  public static AIRBAG_DEPLOYMENT = {id: 'AIRBAG_DEPLOYMENT', text: 'Did the airbags deploy'};
  public static NUM_VEHICLES = {id: 'NUM_VEHICLES', text: 'Number of vehicles involved'};
  public static CAR_SEAT_IN_VEHICLE = {id: 'CAR_SEAT_IN_VEHICLE', text: 'Was there a car seat in vehicle'};
  public static CAR_SEAT_STATUS = {id: 'CAR_SEAT_STATUS', text: 'The status of the car seat in vehicle'};
  public static DATE_OF_LOSS = {id: 'DATE_OF_LOSS', text: 'The date and time of the loss'};
  public static INCIDENT_LOCATION = {id: 'INCIDENT_LOCATION', text: 'The date and time of the loss'};
  public static ENTIRE_VEHICLE_FIRE = {id: 'ENTIRE_VEHICLE_FIRE', text: 'Did the entire vehicle catch on fire'};
  public static ACCIDENT_TYPE = {id: 'ACCIDENT_TYPE', text: 'What happened to cause the accident'};
  public static ACCIDENT_TYPE_OTHER = {id: 'ACCIDENT_TYPE_OTHER', text: 'Details of accident type other'};
  public static WEATHER_TYPE_DETAILS = {id: 'WEATHER_TYPE_DETAILS', text: 'Details of accident type other'};
  public static VEHICLE_RECOVERED = {id: 'VEHICLE_RECOVERED', text: 'Was the vehicle recovered'};
  public static VEHICLE_STOLEN = {id: 'VEHICLE_STOLEN', text: 'Was the vehicle recovered'};
  public static ANY_INJURIES = {id: 'ANY_INJURIES', text: 'Were there any injuries'};
  public static HIT_AND_RUN = {id: 'HIT_AND_RUN', text: 'Was your vehicle hit by driver'};
  public static PET_INJURIES = {id: 'PET_INJURIES', text: 'Were there any pets injured'};
  public static POLICE_REPORT_FILED = {id: 'POLICE_REPORT_FILED', text: 'Was a police report filed'};
  public static POLICE_REPORT_DETAILS = {id: 'POLICE_REPORT_DETAILS', text: 'The police report name and identifier'};
  public static RESPONSIBLE_PARTY = {id: 'RESPONSIBLE_PARTY', text: 'Is the responsible party known'};
  public static SPECIALTY_VEHICLE = {id: 'SPECIALTY_VEHICLE', text: 'Is the vehicle a specialty vehicle'};
  public static PARKED_VEHICLE_DETAILS = {id: 'PARKED_VEHICLE_DETAILS', text: 'Was the vehicle parked or unoccupied'};
  public static VEHICLE_OCCUPIED_QUESTION = {id: 'VEHICLE_OCCUPIED_QUESTION', text: 'Was the vehicle parked or unoccupied'};
  public static INCIDENT_WITNESS = {id: 'INCIDENT_WITNESS', text: 'Was there a witness to the incident'};
  public static ROAD_HAZARDS = {id: 'ROAD_HAZARDS', text: 'Were there road debris or hazards'};
  public static RIDE_SHARE_SCENARIO = {id: 'RIDE_SHARE_SCENARIO', text: 'What is the scenario you were in'};
  public static WORK_RELATED_USE = {id: 'WORK_RELATED_USE', text: 'Was the use work related'};
  public static PURPOSE_OF_TRIP = {id: 'PURPOSE_OF_TRIP', text: 'What is the purpose of trip'};
  public static PURPOSE_OF_TRIP_WORK_RELATED = {id: 'PURPOSE_OF_TRIP_WORK_RELATED' , text: 'What is the purpose of trip'}
  public static TRANSPORTATION_NETWORK_COMPANY={id:'TRANSPORTATION_NETWORK_COMPANY', text: "were you logged on to a Transportation Network Company app "};
  public static HAVE_CLAIMANT_CONTACT = {id: 'HAVE_CLAIMANT_CONTACT', text: 'Have claimant contact info'};
  public static CLAIMANT_ROLE = {id: 'CLAIMANT_ROLE', text: 'Role of the claimant'};
  public static CLAIMANT_NAME_KNOWN = {id: 'CLAIMANT_NAME_KNOWN', text: 'Is claimant name known'};
  public static CLAIMANT_VEHICLE_KNOWN = {id: 'CLAIMANT_VEHICLE_KNOWN', text: 'Is claimant vehicle known'};
  public static DISPUTED_LIABILITY = {id: 'DISPUTED_LIABILITY', text: 'Do all parties agree on who is responsible for the accident'};
  public static LOSS_DESCRIPTION = {id: 'LOSS_DESCRIPTION', text: 'Description of the loss'};
  public static CONFIRM_NO_ADNTL_VEHICLES = {id: 'CONFIRM_NO_ADNTL_VEHICLES', text: 'No additional vehicles'};
  public static LR_AUTH_PASSED = {id: 'LR_AUTH_PASSED', text: 'Authentication Successful'};
  public static LR_AUTH_FAILED = {id: 'LR_AUTH_FAILED', text: 'Authentication Failed'};
  public static LR_OKTA_AUTH_PASSED = {id: 'LR_OKTA_AUTH_PASSED', text: 'Authentication Successful'};
  public static LR_OKTA_AUTH_FAILED = {id: 'LR_OKTA_AUTH_FAILED', text: 'Authentication Failed'};
  public static LR_SELECTED_FLOW = {id: 'LR_SELECTED_FLOW', text: ''};
  public static AZURE_TOKEN_SUCCESS = {id: 'AZURE_TOKEN_SUCCESS', text: ''};
  public static AZURE_TOKEN_FAIL = {id: 'AZURE_TOKEN_FAIL', text: ''};
  public static REPAIR_ELIG_SUCCESS = {id: 'REPAIR_ELIG_SUCCESS', text: ''};
  public static REPAIR_ELIG_FAIL = {id: 'REPAIR_ELIG_FAIL', text: ''};
  public static REPAIR_ELIG_VALUE_TRUE = {id: 'REPAIR_ELIG_VALUE_TRUE', text: 'eligibility_decision == true'};
  public static REPAIR_ELIG_VALUE_FALSE = {id: 'REPAIR_ELIG_VALUE_FALSE', text: 'eligibility_decision == false'};
  public static LR_CRACR_SUCCESS = {id: 'LR_CRACR_SUCCESS', text: 'retrieved relationships successfully'};
  public static LR_CRACR_FAIL = {id: 'LR_CRACR_FAIL', text: 'failed to retrieved relationships'};

  public static CIH_EVENT_CANCELLED = {id: 'CIH_EVENT_CANCELLED', text: 'CIH EVENT CANCELLD'};
  public static CIH_EVENT_SENT = {id: 'CIH_EVENT_SENT', text: 'The CIH event has been sent to media events publisher'};

  public static AUTHGUARD_SSO_SUCCESS = {id: 'AUTHGUARD_SSO_SUCCESS', text: 'Auth Guard SSO request successful'};
  public static AUTHGUARD_SSO_UNAUTH_LOA = {id: 'AUTHGUARD_SSO_UNAUTH_LOA', text: 'Auth Guard SSO unauthorized LOA level'};
  public static AUTHGUARD_SSO_BAD_REQUEST_ERROR = {id: 'AUTHGUARD_SSO_BAD_REQUEST_ERROR', text: 'Auth Guard SSO bad request error returned'};
  public static AUTHGUARD_SSO_REQUEST_ERROR = {id: 'AUTHGUARD_SSO_REQUEST_ERROR', text: 'Auth Guard SSO request encountered error'};
  public static AUTHGUARD_OKTA_REFRESH_SUCCESS = {id: 'AUTHGUARD_OKTA_REFRESH_SUCCESS', text: 'Auth Guard OKTA token refresh successful'};
  public static AUTHGUARD_OKTA_REFRESH_FAILED = {id: 'AUTHGUARD_OKTA_REFRESH_FAILED', text: 'Auth Guard OKTA token refresh failed'};
  public static AUTHGUARD_OKTA_STILL_ACTIVE = {id: 'AUTHGUARD_OKTA_STILL_ACTIVE', text: 'Auth Guard OKTA token still active'};
  public static AUTHGUARD_REDIRECT_LOGIN = {id: 'AUTHGUARD_REDIRECT_LOGIN', text: 'Auth Guard redirection to Login'};
  public static AUTHGUARD_REDIRECT_ERROR = {id: 'AUTHGUARD_REDIRECT_ERROR', text: 'Auth Gurad redirection to error page'};
  public static VEHICLE_STORAGE_FEE = {id: 'VEHICLE_STORAGE_FEE', text: 'Are you being charged storage fees'};

  public static ASSURED_RETRIEVE_AUTH_CODE = {id: 'ASSURED_RETRIEVE_AUTH_CODE', text: 'Retrieving AuthCode from OAuth Server'};
  public static ASSURED_REDIRECT_SUCCESS = {id: 'ASSURED_REDIRECT_SUCCESS', text: 'Redirecting to Assured Claims Successful'};
  public static ASSURED_REDIRECT_FAILURE = {id: 'ASSURED_REDIRECT_FAILURE', text: 'Redirecting to Assured Claims Failed Continuing in OLR Flow'};
}
